section[name="team"] {
  padding-top: 80px;
  padding-bottom: 80px;

  border-left: 10px solid #1c1c1c;
  border-right: 10px solid #1c1c1c;

  .container__center {
    overflow: hidden;

    padding-top: 60px;

  }

  .main__title {
    // color: #f2f3f3;
    mix-blend-mode: difference;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    font-size: 2.6em;
    letter-spacing: 1px;
    margin: 0;

  }

  .main__title span {
    color: #ff2d71;
  }

  .paragraphe {
    mix-blend-mode: difference;
  }

  .main__subtitle {
    margin: 2px 0;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: .9em;
    // color: #f2f3f3;

    mix-blend-mode: difference;
  }

  @media (min-width:768px) {
    .main__subtitle {
      font-size: 1.1em;
    }
  }

  .main__subtitle span {
    font-family: "Patua One", cursive;
    // color: #00a8af;
    font-style: italic;

    mix-blend-mode: difference;
  }


  .link {
    display: inline-block;
    width: auto;
    position: relative;
    text-decoration: none;
    // color: #21272b;
    font-family: Montserrat, sans-serif;
    font-size: .7em;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    // color: #00a8af !important;
    mix-blend-mode: difference;
    transition: all .3s ease-in-out;
  }

  .link:hover {
    letter-spacing: 2px;
  }

  .link__apply {
    display: block;
    width: 150px;
    padding: 16px 12px;
    background-color: #00a8af;
    border: 2px solid #00a8af;
    border-radius: 1px;
    // color: #f2f3f3;
    mix-blend-mode: difference;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: .813em;
    letter-spacing: 1px;
    transition: all .3s ease-in-out;
  }

  .link__apply:hover {
    background-color: transparent;
    letter-spacing: 3px;
    border-radius: 1px;
  }

  .link__apply:active {
    background-color: transparent;
    letter-spacing: 3px;
    border-radius: 1px;
  }

  .link__apply:focus {
    background-color: transparent;
    letter-spacing: 3px;
    border-radius: 1px;
  }

  .swiper-container {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    cursor: -webkit-grab;
    cursor: grab;
  }

  @media (max-width: 1076px) {
    .swiper-container {
      // padding-bottom: 60px;
    }
  }

  @media (min-width:1200px) {
    .swiper-container {
      // height: 500px;
      height: 600px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 50px;
    height: 50px;
    background-image: none;
    // background-color: #e4e4e4;
    background-color: #1c1c1c;
    color: #fff;
    z-index: 2;
    cursor: pointer;
  }

  .swiper-button-next i,
  .swiper-button-prev i {
    position: relative;
    left: 50%;
    top: 50%;
    color: #21272b;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out;
  }

  @keyframes arrowRight {
    0% {
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }

    50% {
      -webkit-transform: translate(-10px, -50%);
      transform: translate(-10px, -50%);
    }

    100% {
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }

  .swiper-button-next:hover i,
  .swiper-button-prev:hover i {
    -webkit-animation: arrowRight 1s infinite;
    animation: arrowRight 1s infinite;
  }

  .swiper-button-next {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }

  @media (min-width:1200px) {
    .swiper-button-next {
      // right: 300px;
      right: 480px;
      // background-color: #f2f3f3;
      background-color: #1c1c1c;
    }
  }

  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
  }

  @media (min-width:1200px) {
    .swiper-button-prev {
      // bottom: 74px;
      bottom: 0;
      // background-color: #f2f3f3;

      background-color: #1c1c1c;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .swiper-slide__block {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    text-align: left;
  }

  .swiper-slide__block .swiper-slide__block__img {
    width: 100%;
    height: auto;

    // max-width: 700px;
    /*   overflow: hidden; */
  }

  .swiper-slide__block .swiper-slide__block__img a {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (min-width:1200px) {
    .swiper-slide__block .swiper-slide__block__img {
      // width: 65%;
      width: 55%;
      // height: 476px;swiper-button-prev
      // height: 500px;
      height: 600px;
      // max-height: 476px;
      // max-height: 500px;
      max-height: 600px;
      // max-width: 735px;
      max-width: 600px;
      overflow: hidden;
      position: relative;
    }
  }



  .swiper-slide__block .swiper-slide__block__img img {
    width: 100%;
    height: auto;
    transition: all .3s ease-in-out;
  }

  .swiper-slide__block .swiper-slide__block__img img {
    // width: 84%;
    width: 100%;
  }

  @media (min-width:1200px) {
    .swiper-slide__block .swiper-slide__block__img img {
      position: absolute;
      top: 0;
      left: 0;
      // width: auto;
      height: 100%;
    }
  }



  .swiper-slide__block .swiper-slide__block__img:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: brightness(.5);
    filter: brightness(.5);
  }

  .swiper-slide__block .swiper-slide__block__text {
    width: 85%;
    height: auto;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
  }

  @media (min-width:768px) {
    .swiper-slide__block .swiper-slide__block__text {
      margin-top: 100px;
    }
  }

  @media (min-width:1200px) {
    .swiper-slide__block .swiper-slide__block__text {
      width: 40%;
      height: 100%;
      // max-height: 476px;
      min-height: 500px;
      max-width: 400px;
      position: absolute;
      background-color: transparent;
      top: 0;
      // right: 0;
      right: 50px;
      margin-top: 0;
      padding: 0;
    }

    // .swiper-slide__block .swiper-slide__block__text:before {
    //   content: "";
    //   display: block;
    //   width: 200px;
    //   max-width: 408px;
    //   height: 100%;
    //   // background-color: #e4e4e4;
    //   background-color: #fff;
    //   position: absolute;
    //   left: -100px;
    // }
  }

  .swiper-slide__block .main__title {
    color: #f2f3f3;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    font-size: 2.6em;
    letter-spacing: 1px;
    margin: 0;
    text-shadow: 7px 7px 16px #d2d2d2;

    @media screen and (max-width: 426px) {
      font-size: 2em;
    }
  }

  .swiper-slide__block .main__title span {
    // color: #ff2d71;
    color: #ffdc2b;
  }

  @media (min-width:768px) {
    .swiper-slide__block .main__title {
      font-size: 3em;
    }
  }



  @media (min-width:1200px) {
    .swiper-slide__block .main__title {
      // margin-top: 100px;
      margin-top: 20px;
    }
  }

  .swiper-slide__block .main__subtitle {
    margin: 2px 0;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: .9em;
    color: #f2f3f3;
  }

  @media (min-width:768px) {
    .swiper-slide__block .main__subtitle {
      font-size: .9em;
    }
  }

  .swiper-slide__block .main__subtitle span {
    font-family: "Patua One", cursive;
    font-style: normal;
    // color: #00a8af;
    // color: #FF2d71;
    color: #ffdc2b;
    letter-spacing: 1px;
  }

  .swiper-slide__block .main__subtitle,
  .swiper-slide__block .main__title,
  .swiper-slide__block .paragraphe {
    // color: #21272b;
    color: #fff;
    z-index: 2;
    position: relative;
  }

  .swiper-slide__block .paragraphe {
    max-width: 413px;
    // text-shadow: 7px 7px 16px #d2d2d2;
    margin-top: 24px;

    line-height: 1.15;

    font-family: "Patua One", cursive;

    @media screen and (max-width: 768px) {
      padding-bottom: 80px;

      font-size: 18px;
    }
  }

  .swiper-slide__block .number {
    font-family: Montserrat, sans-serif;
    font-size: 16em;
    font-weight: 900;
    width: 100%;
    display: block;
    // color: rgba(16, 47, 65, .04);
    color: rgba(255, 255, 255, .04);
    position: relative;
    bottom: 100px;
    z-index: 1;
    text-align: right;
    margin: 0;
    line-height: 120px;
  }

  @media (min-width:1200px) {
    .swiper-slide__block .number {
      margin-top: 100px;
      // font-size: 15em;
      font-size: 4em;
    }
  }


  .loading {
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
  }

  .loading .loading__elements {
    width: 100%;
    height: 50%;
  }

  .loading .loading__elements #progress {
    display: block;
    width: 300px;
    height: 30px;
    z-index: 1;
    font-family: Montserrat, sans-serif;
    background-color: #000;
    transition: all .3s ease-in-out;
  }

  .loading .loading__elements #progress::-webkit-progress-bar {
    display: block;
    width: 100%;
    transition: all .3s;
  }

  .loading .loading__elements #progress::-webkit-progress-value {
    background-color: rgba(131, 201, 207, .8);
    background: -webkit-linear-gradient(#00a8af, #83c9cf);
  }

  .loading .loading__elements progress[value]::-webkit-progress-bar {
    background-color: #f8f8f8;
    border-radius: 2px;
    box-shadow: none;
  }

  .loading .loading__elements #display {
    display: block;
    font-size: 5em;
    z-index: 2;
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    text-align: center;
    margin-bottom: 50px;
    color: #21272b;
  }


  width: 100%;
  height: auto;
  // background-color: #f2f3f3;
  background-color: #1c1c1c;

  @media (min-width:1200px) {
    & {
      // padding: 150px 0;
    }
  }

  & .container__center {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width:992px) {
    & .container__center {
      max-width: 1200px;
      width: 85%;
    }

    // .section__slider .container__center:after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 50px;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 2;
    //   background: linear-gradient(to top, #f2f3f3, rgba(248, 248, 248, 0));
    // }
  }

  @media (min-width:992px) {
    & .container__center {
      max-width: 1070px;
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .swiper-slide__block .swiper-slide__block__img img {
      // max-height: 550px;
      // width: 70%;

      max-height: 600px;
      width: 60%;
    }
  }

  @media (max-width: 576px) {
    .swiper-slide__block .swiper-slide__block__img img {
      // max-height: 550px;
      // width: 70%;

      max-height: 550px;
      width: 70%;
    }
  }
}
