// #validate-output,
// #validate-output2 {
//   position: absolute;
//   top: 45.4%;
//   left: 30px;
// }

section[name="contacts"] {
  padding-top: 80px;
  padding-bottom: 60px;

  background-attachment: fixed;
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1)), url("../images/cont-1.jpg");

  background-image: url("../images/cont-1.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  border-left: 10px solid #1c1c1c;
  border-right: 10px solid #1c1c1c;

  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.8);

  & .contacts {

    color: #1c1c1c;

    h2 {
      color: white;
    }


    &__content {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;
      padding-bottom: 60px;

      @media screen and (max-width: 990px) {
        flex-direction: column;
        row-gap: 20px;

      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      justify-content: space-between;

      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: row;
      }
    }

    & .general {
      display: flex;
      flex-direction: column;
      row-gap: 28px;


      >div {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

      }

      p {
        font-size: 11px;
        // color: white;
        color: #1c1c1c;
        opacity: 0.6;
      }

      a {
        font-size: 22px;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }

        &:hover {
          color: #838383;
          transition: color 0.3s ease-in-out;
        }
      }
    }

    & .social {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      p {
        font-size: 11px;
        // color: white;
        color: #1c1c1c;
        opacity: 0.6;
      }

      &__links {
        display: flex;
        column-gap: 10px;
      }

      &__link {
        font-size: 9px;
        text-transform: uppercase;

        transform-origin: center center;
        background-position: center center;

        position: relative;
        overflow: hidden;

        height: 14px;

        &:hover .hover-accentuation-text {
          transform: translateX(0);
          animation: anim-in-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
          opacity: 1;

          transition-delay: 0s;
        }

        &:hover .hover-accentuation-text::before {
          animation: anim-in-text-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
          opacity: 1;
          transform: translateX(0);
          transition-delay: 0s;
        }
      }

      .hover-accentuation-text {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        display: inline-table;
        width: 100%;
        height: 1px;
        // background: white;
        background: #1c1c1c;
        overflow: hidden;
        pointer-events: none;
        -webkit-animation: anim-out-text-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
        animation: anim-out-text-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);

        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s, transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s;
        transition-delay: 0.7s;



        &::before {
          content: "";
          // background: white;
          background: #1c1c1c;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0;
          transform: translateX(100%);
          display: inline-table;
          width: 100%;
          -webkit-animation: anim-out-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
          animation: anim-out-accentuation 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);

          opacity: 0;
          transition: opacity 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s, transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s;
          transition-delay: 0.7s;
        }
      }
    }

    &__form {
      width: 100%;

      h3 {
        font-size: 22px;
        margin-bottom: 6px;

        @media screen and (max-width: 768px) {
          text-align: center;
          font-size: 20px;

        }
      }

      p {
        font-size: 11px;
        // color: white;
        color: #1c1c1c;
        opacity: 0.6;

        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }

      .main-form {
        display: flex;
        flex-direction: column;
        // row-gap: 20px;
        padding-top: 20px;

        &__top {
          display: flex;
          column-gap: 10px;

          @media screen and (max-width: 560px) {
            flex-direction: column;
          }

        }

        :is(.right-block, .left-block) {
          width: 100%;
          display: flex;
          flex-direction: column;

          justify-content: space-between;
        }
      }

      .right-block {
        &__additional-data {
          display: flex;
          column-gap: 8px;
        }
      }

      input:focus {
        transition: all 0.3s ease;
        border-color: #ffdc2b;
      }

      input[type="date"] {
        // color-scheme: dark;

        &::-webkit-calendar-picker-indicator {
          // filter: invert(1);

          &:hover {
            cursor: pointer;
          }
        }
      }

      select {
        background-color: transparent;
        color: #1c1c1c;
        border: none;
        border-bottom: 1px solid #1c1c1c;
        // padding: 10px;
        margin-bottom: 20px;
        outline: none;

        option {
          background-color: black;
          color: rgb(255, 255, 255);
        }

        @media screen and (max-width: 560px) {
          width: 100%;
        }

      }

      input {
        width: 100%;
        outline: none;
        border: none;
        // color: rgb(255, 255, 255);
        color: #1c1c1c;
        border-bottom: 1px solid rgb(40, 40, 40);
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        height: 45px;

        margin-bottom: 20px !important;

        &::placeholder {
          color: #1c1c1c;
          opacity: 0.6;
          font-size: 11px;
        }
      }

      button {
        background-color: #282828;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: white;
          color: #282828;
          border-color: #282828;
        }
      }
    }
  }
}


@keyframes anim-in-accentuation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes anim-out-text-accentuation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}



@keyframes anim-out-accentuation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes anim-in-text-accentuation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
