// reset styles;
@import "vendors/reset";
// normalize styles
@import "vendors/normalize.scss";
// scrollbar
@import "scrollbar";
// tooltips
@import "tooltips";
// fonts
@import "fonts";
// variables
@import "vars";
// mixins
@import "mixins";
// helpers
@import "helpers";


// default styles
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: #1c1c1c;
  font-family: 'JosefinSans', sans-serif;
  font-size: 1.6rem;
  color: $white;
  height: 100%;
  // min-height: 100vh;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  scroll-behavior: smooth;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;

  // Плавный скролл
  scroll-behavior: smooth;

  // Сглаживание шрифтов
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;

  // Fix размытия изображений в Chromium
  image-rendering: -webkit-optimize-contrast;

  font: inherit;
  font-size: inherit;
  color: inherit;

  // outline default false
  outline: none;
}

// Вытягиваем все картинки по ширине и убираем отступ
img {
  vertical-align: top;
  object-fit: cover;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

@media (hover: hover) {
  button:hover {
    background-color: #ffdc2b;
  }
}

// end default styles

// toggle checkbox
// checkbox
// .switch {
//   position: relative;
//   display: inline-block;
//   width: 38px;
//   height: 23px;
//   min-width: 38px;

// }

.btn-link {
  background-color: #fff;
  color: #1c1c1c;
  padding: 12px 10px;
  border-radius: 60px;
  border: 1px solid transparent;
  -webkit-transition: .3s;
  transition: .3s;

  &:hover {
    color: #fff;
    background-color: #1c1c1c;
  }
}

.vertical-hr {
  min-width: 2px;
  // height: 20px;
  min-height: 100%;
  background-color: #282828;

  margin-right: 20px;
  margin-left: 20px;

  align-self: stretch;
}

.horizontal-hr {
  min-width: 100%;
  // height: 20px;
  min-height: 2px;
  background-color: #282828;

  margin-top: 20px;
  margin-bottom: 20px;

  align-self: stretch;
}

.horizontal-hr.mt {
  margin-top: 40px;
}

h2.rounded,
h1.rounded {
  background-color: #282828;
  border: 1px solid #282828;
  border-radius: 30px;
  font-size: 14px;
  padding: 10px 20px;

  margin: 0 auto;
  width: fit-content;

  text-transform: uppercase;
  letter-spacing: 1.3px;

  // text-shadow: 0px 1px 1px #fff, 0 2px 0 #000;
  // font-weight: 600;
}

@media screen and (max-width: 476px) {
  h2.rounded {
    font-size: 12px;
  }
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: transparent !important;
}

.iti__flag-container {
  bottom: 20px !important;
}



// 22222
.swal2-popup.swal2-modal.swal2-show {
  background-color: #282828 !important;
  color: #fff !important;
  padding: 20px;
}

.swal2-success-circular-line-right {
  background-color: #282828 !important;
}

.swal2-success-circular-line-left {
  background-color: #282828 !important;
}

.swal2-success-fix {
  background-color: #282828 !important;
}

.swal2-actions {
  width: 50% !important;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 22px !important;
  line-height: 1.1 !important;
  font-weight: 600 !important;

}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 50em !important;
}

.swal2-footer {
  font-size: 14px !important;
}

.swal2-title {
  font-size: 32px !important;
}


button.swal2-confirm.swal2-styled {
  padding: 8px 12px !important;
  font-size: 18px !important;

  color: #282828 !important;

  background-color: #fff !important;

  width: 100%;

  outline: none !important;
}

@media screen and (max-width: 476px) {
  .swal2-title {
    font-size: 22px !important;
  }

  div:where(.swal2-container) .swal2-html-container {
    font-size: 16px !important;
  }

  button.swal2-confirm.swal2-styled {
    font-size: 14px !important;
  }
}

.services {


  &__list {
    padding-top: 60px;
    padding-bottom: 40px;
    display: flex;
    column-gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;

    justify-content: center;
  }
}

.partners-slider__item {
  width: 250px;
  height: 250px;
  background-color: #fff;
}

.partners-slider {
  // margin-right: -20px;
}

.partners-slider__item {
  // margin-right: 20px;
}


.partners-slider__img {
  background-color: #fff;
  color: #ffffff;
  font-size: 16px;
  min-width: 230px;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  box-shadow: inset 0px 0px 18px rgba(17, 17, 17, 0.973);
}

.partners-slider__img *,
.partners-slider__img *:before,
.partners-slider__img *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.partners-slider__img img {
  backface-visibility: hidden;
}

.partners-slider__item .partners-slider__img img {
  width: 100%;
  height: 100%;
  background: transparent;
}

.partners-slider__img:before,
.partners-slider__img:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: #282828;
  opacity: 0.8;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.partners-slider__img:before {
  -webkit-transform: skew(30deg) translateX(-80%);
  transform: skew(30deg) translateX(-80%);
}

.partners-slider__img:after {
  -webkit-transform: skew(-30deg) translateX(-70%);
  transform: skew(-30deg) translateX(-70%);
}

.partners-slider__img figcaption {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0;
  padding: 25px 40% 25px 20px;

  color: #282828;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    position: absolute;
    top: 0;
    left: 20px;
  }

  img.img-touch {
    width: 60px;
    height: 60px;

    position: absolute;
    top: 0;
    left: 0;
  }

}

.partners-slider__img figcaption:before,
.partners-slider__img figcaption:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #ffdc2b;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  content: "";

  opacity: 0.9;
  z-index: -1;
}

.partners-slider__img figcaption:before {
  -webkit-transform: skew(30deg) translateX(-100%);
  transform: skew(30deg) translateX(-100%);
}

.partners-slider__img figcaption:after {
  -webkit-transform: skew(-30deg) translateX(-90%);
  transform: skew(-30deg) translateX(-90%);
}

.partners-slider__img h3,
.partners-slider__img p {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}

.partners-slider__img h3 {
  font-family: "Teko", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 10px;

  text-shadow: 0 1px 0 #fff, 0 2px 0 #000;
}

.partners-slider__img p {
  font-size: 0.9em;
}

.partners-slider__img a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 0.4s ease;

  &:hover {
    text-decoration: underline;
  }
}

.partners-slider__img:hover h3,
.partners-slider__img.hover h3,
.partners-slider__img:hover p,
.partners-slider__img.hover p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 0.9;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.partners-slider__img:hover:before,
.partners-slider__img.hover:before {
  -webkit-transform: skew(30deg) translateX(-20%);
  transform: skew(30deg) translateX(-20%);
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.partners-slider__img:hover:after,
.partners-slider__img.hover:after {
  -webkit-transform: skew(-30deg) translateX(-10%);
  transform: skew(-30deg) translateX(-10%);
}

.partners-slider__img:hover figcaption:before,
.partners-slider__img.hover figcaption:before {
  -webkit-transform: skew(30deg) translateX(-40%);
  transform: skew(30deg) translateX(-40%);
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.partners-slider__img:hover figcaption:after,
.partners-slider__img.hover figcaption:after {
  -webkit-transform: skew(-30deg) translateX(-30%);
  transform: skew(-30deg) translateX(-30%);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.partners-slider__img:hover figcaption svg {
  transition: opacity 0.3s ease;
  opacity: 0;

}

.partners-slider__img:hover figcaption img.img-touch {
  transition: opacity 0.3s ease;
  opacity: 0;

}

// 22222

.reviews .swiper-wrapper {
  align-items: center;
}

/* hide custom checkbox and apply custom style to make toggle switch */
// .switch input {
//   display: none;
// }

// .slider {
//   /* style toggle switch itself */
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #FF6C7D;
// }

// .slider::before {
//   /* style toggle switch knob when OFF */
//   position: absolute;
//   content: "";
//   height: 17px;
//   width: 17px;
//   left: 3px;
//   bottom: 3px;
//   background-color: #fff;
//   transition: 0.4s;
// }

// input[type="checkbox"]:checked+.slider::before {
//   /* style toggle switch knob when ON */
//   -webkit-transform: translateX(15.2px);
//   -ms-transform: translateX(15.2px);
//   transform: translateX(15.2px);
// }

// input[type="checkbox"]:checked+.slider {
//   /* style toggle switch background when ON */
//   background-color: #129A97;
// }

/* apply border-radius to switch */
// .slider.round {
//   border-radius: 16px;
// }

// .slider.round::before {
//   border-radius: 50%;
// }

// end toggle checkbox

/* Blocks */
@import 'blocks/header';
@import 'blocks/container';
@import 'swiper-bundle';
@import 'blocks/slider';
@import 'blocks/greet';
@import 'blocks/about';
@import 'blocks/portfolio';
@import 'blocks/works';
@import 'blocks/faq';
@import 'blocks/contacts';
@import 'blocks/jobs';
@import 'blocks/404.scss';
@import 'blocks/footer';