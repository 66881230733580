section[name='works'] {
  padding-top: 80px;
  padding-bottom: 80px;

  position: relative;

  background-color: #fff;

  .works {
    &__wrapper {
      // display: flex;
// flex-direction: column;

// padding-top: 100px;

// padding-bottom: 100px;

// row-gap: 100px;
    }

    &__content {
      // display: flex;
// align-items: center;
// justify-content: center;
// column-gap: 8px;

      // &--one {
  //   margin-left: -156px;
  //   margin-bottom: -44px;
  // }
    }

    &__image {
      // position: absolute;
 // top: -40px;
 // width: 150px;
 // height: 75px;

 // z-index: 10;
    }

    &__item {
      // width: 150px;
 // height: 75px;
 // background: #ffdc2b;
 // position: relative;
 // // margin: 10px auto;





 // &:before {
 //   content: '';
 //   width: 0;
 //   height: 0;
 //   position: absolute;
 //   top: -50px;
 //   left: 0;
 //   border-left: 75px solid transparent;
 //   border-right: 75px solid transparent;
 //   border-bottom: 50px solid #ffdc2b;

 //   overflow: hidden;
 //   z-index: 20;
 // }

 // &:after {
 //   content: '';
 //   width: 0;
 //   height: 0;
 //   position: absolute;
 //   bottom: -50px;
 //   left: 0;
 //   border-left: 75px solid transparent;
 //   border-right: 75px solid transparent;
 //   border-top: 50px solid #ffdc2b;

 //   overflow: hidden;
 //   // z-index: 20;
 // }
    }
  }
}
