section[name="portfolio"] {
  padding-top: 80px;
  // padding-bottom: 40px;
  padding-bottom: 80px;

  position: relative;
  overflow: hidden;

  background-attachment: fixed;
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1)), url("../images/cont-1--revert.jpg");
  background-image: url("../images/cont-1--revert.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  border-left: 10px solid #1c1c1c;
  border-right: 10px solid #1c1c1c;

  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.8);

  &#portfolio .lines {
    position: absolute;
    top: 0px;
    right: -10px;
    width: 120px;
    // height: 84px;
  }

  &#reviews {
    & .lines.lines--portfolio {
      position: absolute;
      width: 120px;
      left: 0;
      bottom: 0px;
    }

    & .swiper.reviewsSwiper .swiper-button-prev,
    & .swiper.reviewsSwiper .swiper-button-next {
      color: #282828;
    }
  }

  .portfolio {
    &__content {
      padding-top: 60px;
      padding-bottom: 60px;

      & img {
        width: 220px;
      }

      &--smartphone {
        display: flex;
        column-gap: 20px;

        & .left-items,
        & .right-items {
          color: #282828;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          // align-items: center;
          text-align: center;
          row-gap: 30px;

          font-size: 24px;

          justify-content: space-around;

          flex: 24%;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
          }

          @media screen and (max-width: 776px) {
            font-size: 16px;

          }

        }

        @media screen and (max-width: 776px) {
          flex-direction: column;
          row-gap: 40px;
        }
      }
    }

    & .swiper.portfolioSwiper {
      padding-bottom: 20px;
    }

    & .swiper-slide {
      display: flex;
      justify-content: center;

      &:hover {
        overflow: hidden;

        & img {
          will-change: transform;
          transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
          transform: scale(1.2);
        }
      }
    }

    & .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: -2px;

      & span {
        background-color: #282828;
      }
    }
  }

  // mobile phone
  .smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;


    @media screen and (max-width: 776px) {
      width: 340px;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  /* The horizontal line on the top of the device */
  .smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  /* The circle on the bottom of the device */
  .smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }

  /* The screen (or content) of the device */
  .smartphone__list {
    // width: 360px;
    width: 100%;
    // height: 640px;
    height: 100%;
    background-color: #f8f3f3;
    display: flex;
    flex-direction: column;

    overflow-y: auto;

    scrollbar-width: none;
  }

  .smartphone-item {
    background-color: #f8f3f3;

    padding-top: 14px;
    padding-left: 8px;
    padding-right: 8px;

    display: flex;
    flex-direction: column;

    row-gap: 10px;

    color: #282828;

    &__header {
      display: flex;

      // justify-content: space-between;
      column-gap: 10px;

      @media screen and (max-width: 425px) {
        column-gap: 4px;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__avatar {
      min-width: 30px;
      max-width: 30px;
      height: 30px;

      img {
        width: 100%;
      }
    }

    &__name {
      font-size: 18px;

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &__content {
      font-size: 16px;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }

      padding-bottom: 12px;

      line-height: 1.1;
      color: #1c1c1cb7;

      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #ccc;
    }

    &__data {
      font-size: 12px;

      @media screen and (max-width: 425px) {
        font-size: 11px;
      }

      align-items: center;

      display: flex;
      flex-wrap: wrap;

      row-gap: 4px;

      column-gap: 4px;

      & .colored {
        padding: 4px 6px;
        border-radius: 4px;

        white-space: nowrap;

        line-height: 1.4;
        font-weight: 600;

        // #ffdc2b
        &--lightgreen {
          // color: #1dd8d5;
          // background-color: #035e5c;

          color: #a99114;
          background-color: #ffdc2b;
        }

        &--green {
          // color: #035e5c;
          // background-color: #1dd8d5;

          color: #ffdc2b;
          background-color: #a99114;
        }
      }
    }

    &__star {
      display: flex;
      // align-items: center;
      column-gap: 8px;

      margin-left: auto;

      @media screen and (max-width: 425px) {
        flex-direction: column;
      }

      img {
        width: 16px;
        height: 16px;
      }

      span {
        margin-top: 6px;
        line-height: 0.6;

        @media screen and (max-width: 425px) {
          font-size: 14px;
        }
      }
    }
  }

}



// ПАРТ


// .handymans h2 {
//   color: rgb(101, 8, 213);
//   font-size: 50px;
//   font-weight: 700;
//   text-align: center;
// }

.handymans__content {
  padding-top: 60px;
}

.handymans__item {
  /* display: grid; */
  display: none;
  grid-template-columns: repeat(2, minmax(340px, 1fr));
  justify-content: center;
  grid-gap: 30px;
}

.handymans__item.active {
  display: grid;
}

.handymans__item.active .handymans__item-img {
  animation: fadeInLeft 1s;
}

.handymans__item.active .handymans__item-info {
  animation: fadeInRight 1s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.handymans__item-img {
  border: 1px solid #ddd;
  border-radius: 30px;
  overflow: hidden;
  height: 444px;
  max-width: 700px;
  /* background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; */
}

.handymans__item-img img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

.handymans__item-info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;

  font-family: "JosefinSans", sans-serif;
}

.handymans__item-info-title h3 {
  color: #ffdc2b;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 6px;

  margin-bottom: 4px;
}

.handymans__item-info-title h4 {
  font-size: 16px;
  color: #282828;
  font-weight: 700;

  margin-bottom: 14px;
}

.handymans__item-info-title p {
  color: #282828;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 6px;
  text-decoration: underline;
}

.handymans__item-info-list {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;

  list-style: disc;

  font-family: "JosefinSans", sans-serif;

  >li:not(:last-child) {
    margin-bottom: 6px;
  }
}

.handymans__item-info-title span {
  color: #000;
}

.handymans__item-control {
  display: flex;
  column-gap: 20px;
}

.handymans__item-control button {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: box-shadow 0.4s ease;
}

.handymans__item-control button:not([disabled]):hover {
  cursor: pointer;
  box-shadow: 0px 0px 27px 1px #ffdc2b;
}

.handymans__item-control button[disabled] {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .handymans__item {
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }

  .handymans__item-control {
    justify-content: center;
  }

  .handymans__item-info-title {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .handymans__item-info-title p {
    font-size: 26px;
  }
}

@media screen and (max-width: 526px) {

  .handymans h2 {
    font-size: 28px !important;
  }
}



// 1111
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

:is(.mySwiper1, .mySwiper2, .mySwiper3, .mySwiper4, .mySwiper5, .mySwiper6, .mySwiper7) {
  height: 80%;
  width: 100%;
}

:is(.thumbForSwiper1, .thumbForSwiper2, .thumbForSwiper3, .thumbForSwiper4, .thumbForSwiper5, .thumbForSwiper6, .thumbForSwiper7) {
  height: 20%;
  box-sizing: border-box;
  // padding: 10px 0;
  padding-top: 10px;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// 1111

//
