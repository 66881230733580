header {
  &.walk {
    position: fixed;
    top: 6px;
    left: 0;
    right: 0;
    z-index: 100;

    border-radius: 60px;

    background-color: #1c1c1c;
    opacity: 0.8;
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  & .header {
    // padding-top: 14px;
    // padding-bottom: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 9px;

    letter-spacing: 0.8px;
    font-weight: 600;

    * :not(span) {
      text-transform: uppercase;
    }

    span {
      color: #fff;
      opacity: 0.6;
    }

    & .logo {
      display: flex;
      align-items: center;

      @media screen and (max-width: 578px) {
        span {
          display: none;
        }
      }
    }

    & .logo__img {
      // width: 30px;
      // height: 30px;
      width: 100px;
      border-radius: 50%;

      margin-right: 10px;
    }

    & .nav {
      &__list {
        display: flex;
        align-items: center;

      }

      ul {
        display: flex;
        align-items: center;
        column-gap: 10px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      a {
        padding: 12px 10px;
        border-radius: 60px;
        border: 1px solid transparent;
        transition: all ease 0.3s;

        text-align: center;
        line-height: 1.2;

        white-space: nowrap;

        &:hover {
          // background-color: #282828;
          background-color: #ffdc2b;
          color: #282828;
        }
      }
    }
  }

  @media screen and (max-width: 626px) {
    .vertical-hr {
      margin-right: 10px;
      margin-left: 10px;
    }

    .logo {
      flex-grow: 1;
    }

    &.walk {
      border-radius: 40px;

      padding-right: 10px;
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 410px) {
    #menu-show {
      height: 30px;
      width: 30px;
    }

    .logo__img {
      width: 80px;
    }

    a.btn.btn-link {
      padding: 10px 10px;
    }

    &.walk {
      border-radius: 40px;

      padding-right: 6px;
      padding-left: 6px;

      #menu-show {
        height: 30px;
        width: 30px;
      }

      .logo__img {
        width: 60px;
      }

      .btn.btn-link {
        padding: 8px 8px;
        line-height: 1;
      }
    }
  }
}

// mobile menu
.burger {
  width: 40px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: none;
}

.burger span {
  position: absolute;
  background-color: #434343;
  width: 40px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
}

.burger span:nth-child(2) {
  top: 13px;
}

.burger span:last-child {
  bottom: 0;
  top: unset;
}

/* svg */
.menu-flex-box {
  display: none;
}

@media screen and (max-width: 768px) {
  .menu-flex-box {
    display: flex;
    margin: 6px;
    padding: 0px;
    flex: 0 1 auto;
    z-index: 9999;
  }
}

.header-dropdown {
  position: fixed;
  top: 84px;
  right: 0;
  left: -100%;
  z-index: 9999;
  width: 100%;
  height: 100%;
  /* background-color: #22354a; */
  background-color: #282828;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 410px) {
    top: 64px;
  }

  &.walk {
    top: 90px;

    @media screen and (max-width: 410px) {
      top: 60px;
    }
  }
}

.mobile-nav {
  width: 100%;
}

.header-dropdown-navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.navbar-mobile__item {
  font-size: 16px;
  color: #fff;
  // color: rgba(199, 189, 189, 0.824);
}

.navbar-mobile__item a:hover {
  color: #ffdc2b;
}

.navbar-mobile__item a {
  -webkit-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.navbar-mobile {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 20px;
}

.header-dropdown.show {
  left: 0;
}

.header-dropdown-close-btn {
  display: block;
  position: absolute;
  top: 28px;
  right: 10px;
  width: 50px;
  height: 40px;
  border: none;
  background-color: #22354a;
  background-image: url(../images/svg/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {

    .line {
      transition: all 0.4s ease;
      stroke: #ffdc2b;
    }
  }
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 5.5;
  stroke-linecap: round;

}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -98px;
}

.ham1.active .bottom {
  stroke-dashoffset: -138px;
}


.header-dropdown__icon {}