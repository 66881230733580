section[name="about"] {
  padding-top: 80px;
  padding-bottom: 0px;

  position: relative;
  overflow: hidden;

  .capital-quote p {
    padding-top: 20px;
    line-height: 1.2;
    font-size: 24px;

    text-align: center;
    font-style: italic;

    text-shadow: 0 0 10px #fff, 0 0 10px #000;
  }

  .capital-quote p::first-letter {
    font-size: 30px;
    font-weight: bold;
  }

  .pat {
    position: absolute;
    // top: 20px;
    left: 0;
    // width: 84px;
    // height: 84px;
    // z-index: -1;
    left: 20px;
    bottom: 160px;
    // width: 120px;
    // height: 120px;
    width: 10dvw;
    max-width: 120px;

    @media screen and (max-width: 1260px) {
      bottom: 190px;
    }

    @media screen and (max-width: 992px) {
      bottom: 220px;
    }

    @media screen and (max-width: 768px) {
      bottom: 250px;
    }

    @media screen and (max-width: 526px) {
      display: none;
    }
  }

  .lines {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 120px;
    // height: 84px;
  }


  .about {

    &__title {}

    &__content {
      display: flex;
      // align-items: center;
      justify-content: space-between;

      padding-top: 60px;
      padding-bottom: 60px;


      @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 20px;
      }

    }

    &__description {
      display: flex;
      flex-direction: column;
      row-gap: 60px;

      flex: 0 0 48%;
      // width: 100%;
      // max-width: 600px;

      justify-content: space-between;

      font-size: 18px;
      line-height: 1.3;

      // padding-left: 20px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      // max-height: 400px;
    }

    &__images {
      flex: 0 0 48%;
      // width: 100%;
      // max-width: 500px;

      // height: 30dvw;
      // max-height: 340px;
      max-height: 480px;

      position: relative;
      display: flex;

      justify-content: end;
      // padding-right: 20px;

      filter: contrast(0.7);

      padding-top: 4px;


      & .about__image {
        object-fit: fill !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media screen and (max-width: 768px) {

          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      @media screen and (max-width: 1260px) {
        // max-height: 450px;
        max-height: 400px;
      }

      @media screen and (max-width: 994px) {
        // max-height: 350px;
        max-height: 300px;
      }

      @media screen and (max-width: 768px) {
        max-height: 450px;
        width: 100%;

        padding-right: 0;

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    // &__image {
    //   border-radius: 0px;
    //   background-position: center center;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   border-color: transparent;
    //   border-style: solid;

    //   transform-origin: center center;

    //   &--one {
    //     background-image: url("../images/about/iStock-55-big.jpg");
    //     width: 60%;
    //     max-width: 320px;

    //     // position: absolute;
    //     // left: 0;


    //     // height: 100%;
    //     max-height: 400px;
    //   }

    //   &--two {
    //     background-image: url("../images/about/iStock-55-small.jpg");
    //     width: 30%;

    //     height: 20dvw;

    //     // position: absolute;
    //     // right: 160px;
    //     // bottom: -40px;
    //     max-height: 260px;
    //     transform: translateX(calc(100% - 140%)) translateY(calc(100% - 40%));
    //   }
    // }

    &-why {
      &__list {
        display: flex;
        // align-items: center;
        padding-top: 20px;
        justify-content: space-between;

      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: start;



        row-gap: 40px;
        padding: 10px;

        font-size: 11px;

        p {
          text-shadow: 0 0 10px #fff, 0 0 10px #000;
        }

        & img {
          width: 22px;
          height: 22px;
        }

        @media screen and (max-width: 410px) {
          align-items: center;

          p {
            text-align: center;
          }
        }

      }
    }
  }
}
