section[name="jobs"] {
  padding-top: 100px;
  padding-bottom: 100px;

  height: 100%;

  background-color: #fff;
  // background-attachment: fixed;
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  // background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1)), url("../images/cont-1.jpg");

  background-image: url("../images/cont-1.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  border-left: 10px solid #1c1c1c;
  border-right: 10px solid #1c1c1c;

  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.8);

  .jobs {
    &__form {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    p {
      color: #282828;
    }

    &__text {
      max-width: 720px;
      margin: 0 auto;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      p {
        font-size: 20px;
        line-height: 1.2;
      }
    }
  }

  .main-form {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // row-gap: 20px;
    padding-top: 20px;

    color: #282828;

    input {
      width: 100%;
      outline: none;
      border: none;
      // color: rgb(255, 255, 255);
      color: #1c1c1c;
      border-bottom: 1px solid rgb(40, 40, 40);
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      height: 45px;

      margin-bottom: 20px !important;

      &::placeholder {
        color: #1c1c1c;
        opacity: 0.6;
        font-size: 11px;
      }
    }

    button {
      background-color: #282828;
      color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: white;
        color: #282828;
        border-color: #282828;
      }
    }

    p {
      margin-top: 14px;
      font-size: 14px;
    }
  }
}