section[name='faq'] {
  position: relative;
  padding-top: 80px;
  padding-bottom: 40px;

  &#faq {
    & .lines.lines--about {
      position: absolute;
      width: 120px;
      left: 10px;
      top: 0px;
    }
  }

  &#services {
    .lines {
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 120px;
      // height: 84px;
    }
  }


}

// section[name='faq'] .faq {
//   padding-top: 60px;
//   padding-bottom: 60px;
// }

section[name='faq'] .faq__title--decoration {
  position: absolute;
  font-size: 235px;
  line-height: 235px;
  color: #f1f5ff;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 800;
  top: 0;
  right: 0;
}

section[name='faq'] .faq__content {
  padding-top: 60px;
  padding-bottom: 60px;

  &.services__content {
    display: flex;
    column-gap: 40px;
    justify-content: center;

    @media screen and (max-width: 526px) {
      flex-wrap: wrap;

    }
  }
}

.accordion .accordion-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top: 1px solid #282828;
  background: 0 0;
}

.accordion .accordion-item:last-child {
  border-bottom: 1px solid #282828;
}

.accordion .item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-column-gap: 0.2em;
  -moz-column-gap: 0.2em;
  column-gap: 0.2em;
  padding: 20px 10px 20px 0;
  cursor: pointer;
}

.accordion .item-header.active .bx.bx-chevron-down,
.accordion .item-header:hover .bx.bx-chevron-down {
  // color: #06f;
  color: #fff;
}

.accordion .item-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  font-size: 1.25rem;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background: 0 0;
  cursor: pointer;
}

.accordion .item-icon i {
  -webkit-transition: 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  -o-transition: 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  transition: 0.25s cubic-bezier(0.5, 0, 0.1, 1);
}

.accordion .item-question {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}

.faq__content:not(.services__content) .accordion .active .item-icon i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion .active .item-question {
  font-weight: 500;
}

.accordion .item-content {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.accordion .item-answer {
  line-height: 24px;
  display: inline-block;
  padding-bottom: 10px;
  font-size: 14px;
}

.faq__content .accordion .bx.bx-chevron-down {
  -webkit-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s;
  font-size: 25px;
  // color: #06f;
  color: #282828;
}

.faq__content.services__content .accordion .bx.bx-chevron-down {
  font-size: 45px;
  line-height: 0;
}

@media screen and (max-width: 1026px) {
  section[name='faq'] .faq {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  // section[name='faq'] .faq__title {
  //   font-size: 24px;
  // }

  section[name='faq'] .faq__title--decoration {
    display: none;
  }
}