// fonts
// @font-face {
//     font-family: 'Roboto Thin';
//     src: url(../fonts/Roboto-Thin.ttf) format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-Thin.ttf) format('ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-ExtraLight.ttf) format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-Light.ttf) format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-Regular.ttf) format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-Medium.ttf) format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans';
  src: url(../fonts/JosefinSans-Bold.ttf) format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'JosefinSans';
//   src: url(../fonts/FuturaPT-ExtraBold.woff) format('woff');
//   font-weight: 800;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'JosefinSans';
//   src: url(../fonts/FuturaPT-Heavy.woff) format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

