footer {
  background-color: #282828;
  color: white;
  opacity: 0.6;
  padding: 30px 20px;
  font-size: 11px;

  .copyright {
    text-align: center;
  }
}