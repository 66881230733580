.wa {
  width: auto !important;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.w50 {
  width: 50% !important;
}

.h50 {
  height: 50% !important;
}

.p0 {
  padding: 0;
}

.pr0 {
  padding-right: 0;
}

.pl0 {
  padding-left: 0;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.mr0 {
  margin-right: 0;
}

.m0a {
  margin: 0 auto;
}

.df {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdr {
  flex-direction: row;
}


.aic {
  align-items: center;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}

.jfcc {
  justify-content: center;
}

.jfcsb {
  justify-content: space-between;
}

.jfcsa {
  justify-content: space-around;
}

.jfcfe {
  justify-content: flex-end;
}

.jfcfs {
  justify-content: flex-start;
}

.tac {
  text-align: center;
}

.posa {
  position: absolute;
}

.posr {
  position: relative;
}

.posf {
  position: fixed;
}

.oh {
  overflow: hidden;
}

