// .container {
//     width: 100%;
//     height: 100%;
//     max-width: 1440px;
//     margin: 0 auto;
//     padding: 0 4rem;
// }

/* Контейнер */
.container {
  // max-width: 1240px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1460px) {
  .container {
    max-width: 1240px;
  }
}

@media screen and (max-width: 1260px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: calc(100% - 30px);
    margin: 0 15px;
  }
}
