section[name='greet'] {
  // background-attachment: fixed;
  // background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.7)), url("../images/map.jpg");

  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.7)), url("../images/map.webp");

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: contain;

  // background-position-y: 50px;
  // @media screen and (max-width: 526px) {
  //   background-position-y: -20px;

  // }

  width: 100%;
  // max-height: 800px;
  max-height: 1000px;
  // height: 80dvw;
  height: 82dvw;


  @media screen and (min-width: 1920px) {
    max-height: 1200px;
  }

  // padding-top: 300px;
  // padding-bottom: 300px;

  // padding-top: 15dvw;
  // padding-bottom: 15dvw;

  position: relative;

  border-left: 10px solid #1c1c1c;
  border-right: 10px solid #1c1c1c;

  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.8);

  // overflow-x: hidden;
  overflow: hidden;

  @media screen and (max-width: 990px) {
    // max-height: 1200px;
    max-height: 1500px;
    height: auto;

    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url("../images/map.jpg");
  }






  & .container {
    height: 100%;
  }

  .greet {
    display: flex;
    height: 100%;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;

    column-gap: 20px;

    @media screen and (max-width: 990px) {
      flex-direction: column;
      row-gap: 40px;

      padding-top: 60px;
      padding-bottom: 120px;

      // max-height: 800px;
      // height: 80dvw;
    }

    & .left-block {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 50%;
      position: relative;
      z-index: 5;

      &-form {
        padding: 20px;
      }

      .contacts__form {
        position: relative;
        z-index: 5;
      }

      .greet__description {
        position: relative;
        z-index: 5;
      }

      .cover {
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        // background: rgba(255, 255, 255, 0.15);
        background: rgba(0, 0, 0, 0.15);
        height: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        // width: 300px;
        z-index: 0;
        border-radius: 10px;
        // padding: 20px;
      }
    }

    & .right-block {
      display: flex;
      flex-direction: column;
      width: 50%;
      // height: 100%;
    }

    & :is(.right-block, .left-block) {
      @media screen and (max-width: 990px) {
        width: 100%;
      }
    }

    &__images {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 20px
    }

    &-image {
      user-select: none;
      max-height: 360px;

      width: 60vh;
      max-width: 100%;

      img {
        height: 100%;
        border-radius: 10px;
      }


      &--one {
        // background: url("../images/greet-img-1.webp") no-repeat;
        transform: translate3d(0, 10%, 0) translateX(8%) rotate(-0.5deg);
        // filter: saturate(0.5) drop-shadow(2px 4px 6px black);

        @media screen and (max-width: 768px) {

          transform: translate3d(0, 12%, 0) translateX(3%) rotate(-0.5deg);
        }

        img {
          max-height: 360px;
        }
      }

      &--two {
        // background: url("../images/greet-img-2--1.webp") no-repeat;
        transform: translate3d(0, -6%, 0) translateX(15%) rotate(1.5deg);
        // filter: saturate(0.5) drop-shadow(2px 4px 6px black);

        @media screen and (max-width: 768px) {

          transform: translate3d(0, -6%, 0) translateX(5%) rotate(1.5deg);
        }
      }
    }

    &__description {
      // max-width: 450px;
      position: relative;
      font-weight: 600;
      color: white;
      letter-spacing: 0.6px;

      & span {
        color: #ffdc2b;
      }

      h2 {
        font-size: 32px;

        margin-bottom: 20px;

        @media screen and (max-width: 526px) {
          font-size: 24px;
        }

        @media screen and (max-width: 420px) {
          font-size: 20px;
        }
      }

      & p {
        margin-bottom: 10px;
      }

      p {
        line-height: 1.3;
        font-size: 18px;

        @media screen and (max-width: 526px) {
          font-size: 14px;
        }
      }

      &::before {
        content: url("../images/svg/arrow.svg");
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        margin-bottom: 20px;
        top: -50px;
      }

      // @media screen and (max-width: 990px) {
      //   order: -1;
      // }

    }

    .contacts__form {
      width: 100%;
      // padding: 0 20px;

      h3 {
        font-size: 22px;
        margin-bottom: 6px;

        @media screen and (max-width: 990px) {
          text-align: center;
          font-size: 20px;

        }
      }

      p {
        font-size: 11px;
        color: white;
        // color: #1c1c1c;
        opacity: 0.6;

        @media screen and (max-width: 990px) {
          text-align: center;
        }
      }

      .main-form {
        display: flex;
        flex-direction: column;
        // row-gap: 20px;
        padding-top: 20px;
        max-width: 650px;

        @media screen and (max-width: 990px) {
          margin: 0 auto;
        }

        &__top {
          display: flex;
          column-gap: 10px;

          @media screen and (max-width: 560px) {
            flex-direction: column;
          }

        }

        :is(.right-block, .left-block) {
          width: 100%;
          display: flex;
          flex-direction: column;

          justify-content: space-between;
        }

        .right-block {
          &__additional-data {
            display: flex;
            column-gap: 8px;
          }
        }

        input:focus {
          transition: all 0.3s ease;
          border-color: #ffdc2b;
        }

        input[type="date"] {
          // color-scheme: dark;

          &::-webkit-calendar-picker-indicator {
            filter: invert(1);

            &:hover {
              cursor: pointer;
            }
          }
        }

        select {
          background-color: transparent;
          color: rgb(255, 255, 255);
          border: none;
          border-bottom: 1px solid rgb(255, 255, 255);
          // padding: 10px;
          margin-bottom: 20px;
          outline: none;

          option {
            background-color: black;
            color: rgb(255, 255, 255);
          }

          @media screen and (max-width: 560px) {
            width: 100%;
          }

        }

        input {
          width: 100%;
          outline: none;
          border: none;
          color: rgb(255, 255, 255);
          // color: #1c1c1c;
          // border-bottom: 1px solid rgb(40, 40, 40);
          border-bottom: 1px solid rgb(255, 255, 255);
          background-color: transparent;
          font-size: 14px;
          font-weight: 400;
          height: 45px;

          margin-bottom: 20px !important;

          &::placeholder {
            // color: #1c1c1c;
            color: white;
            opacity: 0.6;
            font-size: 11px;
          }
        }

        button {
          // background-color: #282828;
          background-color: white;
          // color: white;
          color: 282828;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          width: 100%;

          &:hover {
            background-color: #282828;
            color: white;
            border-color: white;
          }
        }

        @media screen and (max-width: 1260px) {
          .iti__selected-dial-code {
            font-size: 12px;
          }
        }
      }
    }

  }

  .anim-text {
    & span {
      display: inline-block;
      // margin-right: 80px;
    }

    // font-size: 150px;

    font-size: 12dvw;


    opacity: 0.5;

    @media screen and (max-width: 526px) {
      font-size: 10dvw;

    }

    @media screen and (min-width: 1920px) {
      font-size: 9dvw;
      text-align: right;
    }

    position: absolute;
    // bottom: 30px;
    bottom: 0px;
    line-height: 0.8;


    white-space: nowrap;
    word-break: normal;

    display: table-cell;
    width: 100%;
    -webkit-text-size-adjust: 100%;

    animation: sbs 8s infinite linear;
    backface-visibility: hidden;

    will-change: transform;
  }

  @keyframes sbs {
    0% {
      // transform: translateX(160px);
      transform: translateX(40px);
      animation-timing-function: linear;
    }

    // 30% {
    //   // transform: translateX(160px);
    //   transform: translateX(-140%);
    //   // animation-timing-function: linear;
    // }

    // 60% {
    //   // transform: translateX(160px);
    //   transform: translateX(0px);
    //   // animation-timing-function: linear;
    // }

    100% {
      // transform: translateX(-1210px);
      transform: translateX(-114%);
    }
  }
}
